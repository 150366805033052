import { useState, useEffect } from 'react';
import { BiomarkerList, Biomarker, BiomarkerListResponse, BiomarkerResponse } from '@models/Biomarker';
import Endpoints from '@services/Endpoints';
import useApi from './useApi';
import Logger from '@util/Logger';
import useAuth from './useAuth';
import { getValidValue } from '../util/StringUtil';

const defaultFilters: BiomarkerListFilters = { search: '', sort_by: 'name', limit: 100, offset: 0 };

export type BiomarkerListFilters = { search?: string; sort_by?: string; limit?: number; offset?: number };
type Props = { listSlug?: string; biomarkerId?: string; targetType?: string };

const useBiomarkers = ({ listSlug, biomarkerId, targetType }: Props) => {
    const { authReady, user, isLoggedIn } = useAuth();
    const [allBiomarkers, setAllBiomarkers] = useState<Biomarker[]>([]);
    const [hasBiomarkers, setHasBiomarkers] = useState<boolean>(false);
    const [biomarkerCount, setBiomarkerCount] = useState<number>(0);
    const [biomarkerResponse, setBiomarkerResponse] = useState<BiomarkerResponse>();
    const [sortedBiomarkers, setSortedBiomarkers] = useState<Biomarker[]>([]);
    const [biomarkerLists, setBiomarkerLists] = useState<BiomarkerList[]>([]);
    const [biomarkerList, setBiomarkerList] = useState<BiomarkerList | null>(null);
    const [biomarker, setBiomarker] = useState<Biomarker | null>(null);
    const [biomarkerError, setBiomarkerError] = useState<string>('');
    const [fileError, setFileError] = useState<string>('');
    const [listLoading, setListLoading] = useState<boolean>(true);
    const [biomarkersLoading, setBiomarkersLoading] = useState<boolean>(true);
    const [biomarkerLoading, setBiomarkerLoading] = useState<boolean>(true);

    const [filters, setFilters] = useState<BiomarkerListFilters>(defaultFilters);
    const api = useApi();
    const logger = Logger.make('BiomarkerAPI');
    const targetTypeNameSingular = targetType === 'biomarkers' ? 'biomarker' : 'target';

    useEffect(() => {
        const currentBiomarkers = [...(biomarkerResponse?.items ?? [])];
        setAllBiomarkers(currentBiomarkers);
        const sortedMarkers = currentBiomarkers;
        setSortedBiomarkers(sortedMarkers);
    }, [biomarkerResponse]);

    useEffect(() => {
        if (!authReady) return;

        fetchBiomarkerLists();
    }, [authReady, targetType]);

    useEffect(() => {
        if (!authReady || !listSlug || !biomarkerId) return;

        fetchBiomarker(listSlug, biomarkerId);
    }, [authReady]);

    useEffect(() => {
        if (!listSlug) return;
        if (!authReady || !isLoggedIn || !user) return;

        fetchBiomarkerList(listSlug);
    }, [listSlug, authReady]);

    useEffect(() => {
        if (!listSlug) return;

        fetchBiomarkers(listSlug);
    }, [filters, listSlug]);

    const fetchBiomarkerList = async (id: string) => {
        setBiomarkerError('');
        setListLoading(true);
        try {
            const newList = await api.get<BiomarkerList>(Endpoints.lab.biomarkerSet(id));
            setBiomarkerList(newList);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to fetch biomarker list');
        } finally {
            setListLoading(false);
        }
    };

    const fetchBiomarkers = async (id: string) => {
        setBiomarkerError('');
        setBiomarkersLoading(true);
        try {
            const newMarkers = await api.get<BiomarkerResponse>(Endpoints.lab.biomarkers(id), filters);
            if (!filters.search) setHasBiomarkers(!!newMarkers.count);
            setBiomarkerResponse(newMarkers);
            setBiomarkerCount(newMarkers.count);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to fetch biomarkers');
        } finally {
            setListLoading(false);
            setBiomarkersLoading(false);
        }
    };

    const fetchBiomarkerLists = async () => {
        setBiomarkerError('');
        setListLoading(true);

        try {
            const newLists = await api.get<BiomarkerListResponse>(Endpoints.lab.biomarkerSets());
            const sortedLists = newLists.items
                .filter((list: BiomarkerList) => !list.is_archived)
                .filter((list: BiomarkerList) => list.set_type === targetTypeNameSingular)
                .sort(
                    (a: BiomarkerList, b: BiomarkerList) =>
                        Date.parse(b.created_at ?? '') - Date.parse(a.created_at ?? ''),
                );
            setBiomarkerLists(sortedLists);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to fetch biomarker lists');
        } finally {
            setListLoading(false);
        }
    };

    const fetchBiomarker = async (biomarkerSetId, biomarkerId, query = {}) => {
        setBiomarkerError('');
        setBiomarkerLoading(true);

        if (!biomarkerSetId || !biomarkerId) {
            const errorMessage = 'Missing biomarker set ID or biomarker ID';
            logger.error(errorMessage);
            setBiomarkerError(errorMessage);
            return;
        }

        try {
            const biormarkerData = await api.get<Biomarker>(
                Endpoints.lab.biomarker(biomarkerSetId, biomarkerId),
                query,
            );
            setBiomarker(biormarkerData);
        } catch (error) {
            logger.error(`Failed to fetch biomarker with ID ${biomarkerId} from set ${biomarkerSetId}`, error);
            setBiomarkerError(`Failed to fetch biomarker with ID ${biomarkerId} from set ${biomarkerSetId}`);
            setBiomarkerLoading(false);
        } finally {
            setBiomarkerLoading(false);
        }
    };

    const addBiomarkerList = async (list: BiomarkerList) => {
        setBiomarkerError('');
        setListLoading(true);
        try {
            await api.post(Endpoints.lab.biomarkerSets(), list);

            fetchBiomarkerLists();
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to create new biomarker list');
        } finally {
            setListLoading(false);
        }
    };

    const addBiomarker = async (newBiomarker: Biomarker) => {
        if (!listSlug) return;
        setBiomarkerError('');
        setListLoading(true);
        try {
            await api.post(Endpoints.lab.biomarkers(listSlug), newBiomarker);

            setBiomarkerCount(biomarkerCount + 1);
            await fetchBiomarkerList(listSlug);
            fetchBiomarkers(listSlug);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to create new biomarker');
        } finally {
            setListLoading(false);
        }
    };

    const updateBiomarker = async (marker: Biomarker) => {
        if (!marker.uuid || !listSlug) return;
        setBiomarkerError('');
        setBiomarkersLoading(true);
        const payload: Biomarker = { ...marker, priority: getValidValue(marker.priority, null) };
        try {
            await api.put<Biomarker>(Endpoints.lab.biomarker(listSlug, marker.uuid), payload);
            await fetchBiomarkerList(listSlug);
            fetchBiomarkers(listSlug);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to update biomarker');
        } finally {
            setBiomarkersLoading(false);
        }
    };

    const deleteBiomarker = async (marker: Biomarker) => {
        if (!marker.uuid || !listSlug) return;
        setBiomarkerError('');
        setBiomarkersLoading(true);
        try {
            await api.doDelete(Endpoints.lab.biomarkerDelete(listSlug, marker.uuid));
            await fetchBiomarkerList(listSlug);
            fetchBiomarkers(listSlug);
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to delete biomarker');
        } finally {
            setBiomarkersLoading(false);
        }
    };

    const updateBiomarkerList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        setBiomarkerError('');
        setBiomarkersLoading(true);
        try {
            await api.put<BiomarkerList>(Endpoints.lab.biomarkerSet(list.uuid), list);
            fetchBiomarkerLists();
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to update biomarker list');
        } finally {
            setBiomarkersLoading(false);
        }
    };

    const archiveBiomarkerList = async (list: BiomarkerList) => {
        if (!list.uuid) return;
        setBiomarkerError('');
        setListLoading(true);
        try {
            await api.post<BiomarkerList>(Endpoints.lab.biomarkerSetArchive(list.uuid));
            fetchBiomarkerLists();
        } catch (error) {
            logger.error(error);
            setBiomarkerError('Failed to archive biomarker list');
        } finally {
            setListLoading(false);
        }
    };

    const handleCSVUpload = async (file: File | undefined): Promise<any> => {
        if (!file || !listSlug || !user?.organization?.uuid) return;
        setFileError('');
        setListLoading(true);
        const response: any = await api.apiService.uploadBiomarkers({
            file,
            slug: listSlug,
            orgId: user?.organization?.uuid,
        });
        if (response && 'error' in response) {
            logger.error(response.error);
            const errorMessage =
                (response.error?.response?.data?.details?.[0].message || response.error?.response?.data?.file[0]) ??
                'CSV upload invalid, please check file and try again';
            setFileError(errorMessage);
            setListLoading(false);
            return null;
        }
        await new Promise((resolve) => setTimeout(resolve, 2000)); // give the backend a moment to process
        setHasBiomarkers(true);
        await fetchBiomarkers(listSlug);
    };

    const templateURL = 'https://cdn.bfldr.com/2Q1IPX6I/at/kcxvjk77f8rjr7f7cb59/template_biomarkers_targets';
    const downloadCSVTemplate = () => {
        const a = document.createElement('a');

        a.setAttribute('download', 'upload_template.csv');
        a.setAttribute('href', templateURL);
        a.setAttribute('target', '_blank');
        a.click();
    };

    const clearFileError = () => setFileError('');
    const resetFilters = () => setFilters(defaultFilters);

    return {
        biomarkerResponse,
        filters,
        setFilters,
        resetFilters,
        biomarkerError,
        listLoading,
        biomarkersLoading,
        sortedBiomarkers,
        biomarker,
        fetchBiomarker,
        biomarkerLoading,
        addBiomarker,
        updateBiomarker,
        deleteBiomarker,
        biomarkerList,
        fetchBiomarkerList,
        biomarkerLists,
        addBiomarkerList,
        updateBiomarkerList,
        archiveBiomarkerList,
        handleCSVUpload,
        downloadCSVTemplate,
        clearFileError,
        fileError,
        biomarkerCount,
        hasBiomarkers,
        allBiomarkers,
    };
};

export default useBiomarkers;
