import { useDebounce } from 'react-use';
import { useState } from 'react';
import { Edge, EdgeMarker, MarkerType } from 'reactflow';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';

const defaultEdgeOptions = {
    style: {
        strokeWidth: 2,
        stroke: 'black',
    },
    selectable: true,
    deletable: true,
    type: 'floating',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: 'black',
    },
    label: '',
};

const logger = Logger.make('CanvasEdges');

export type CustomEdge = Omit<Edge, 'markerEnd label'> & { markerEnd?: EdgeMarker; label?: string };

type Props = {
    canvasLoaded?: boolean;
    edges: Edge[];
    experiment_id?: string;
    setEdges: (edges: CustomEdge[]) => void;
};
const useCanvasEdges = ({ canvasLoaded, edges, experiment_id, setEdges }: Props) => {
    const [selectedEdge, setSelectedEdge] = useState<CustomEdge | null>(null);
    const [edgesError, setEdgesError] = useState<string>('');
    const api = useApi();

    useDebounce(
        () => {
            saveEdges();
        },
        500,
        [edges],
    );

    const handleUpdateEdge = (edge) => {
        const filteredEdges = edges.filter((oldEdge) => oldEdge.id !== edge.id);
        const newEdges = [...filteredEdges, edge];
        setSelectedEdge(edge);
        setEdges(newEdges ?? []);
    };

    const saveEdges = () => {
        if (!experiment_id || !canvasLoaded) return;
        try {
            api.put(Endpoints.lab.experiment.canvasFlow(experiment_id), { edges });
        } catch (error) {
            logger.error(new Error('Failed to save edges to canvas'));
            setEdgesError('Failed to save edges to canvas');
        }
    };

    return {
        selectedEdge,
        setSelectedEdge,
        defaultEdgeOptions,
        handleUpdateEdge,
        edgesError,
    };
};

export default useCanvasEdges;
