import { AnalysisShortname } from '@models/analysis/AnalysisType';
import GeneSetCollection from '@models/GeneSetCollection';
import TFTargetCollection from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import { DisplayableShortname } from '../api/ApiTypes';

export type ParameterOption = {
    id: number;
    display_name: string;
    sample_id_count?: number;
    uuid?: string;
    cell_count?: number;
};
export type LatentParameterOption = { uuid: string; display_name: string };
export type StringParameterOption = { id: string; display_name: string; cell_count?: number };

export type AnalysisGroup = ParameterOption & { sample_id_count: number };

export type AnalysisGroups = {
    items: AnalysisGroup[];
    selected_variables: string[];
}[];

export type AnalysisGroupV2 = {
    annotation_set_id?: string;
    cell_count: number;
    display_name: string;
    latent_variable_id?: string;
    sample_count: number;
    uuid: string;
    variable_ids: string[];
};

export type AnalysisGroupV3 = AnalysisGroupV2 & {
    task_id: string;
};
export type AnalysisGroupV3Response = {
    result: AnalysisGroupV3[];
    status: string;
    task_id: string;
    task_type: string;
};

export const DifferentialExpressionPickerFieldTypes =
    'differential_expression, differential_expression_continuous, differential_binding, seurat_differential_expression';

export type Log2FoldChangeDirection = 'both' | 'positive' | 'negative';

export type BiomarkerListAnalysisParameter = {
    uuid: string;
    name: string;
};

export type SimpleComparativeAnalysis = {
    control_group_name: string;
    experimental_group_name: string;
    id: string;
    name: string;
    shortname: AnalysisShortname;
};

export interface BaseAnalysisParameters {
    variables: ParameterOption[];
    assay_sample_count: number;
}

export interface AssaySummaryAnalysisParameters extends BaseAnalysisParameters {
    targets: ParameterOption[];
}

export interface DifferentialExpressionAnalysisParameters extends BaseAnalysisParameters {
    covariates: ParameterOption[];
}
export interface SeuratDifferentialExpressionAnalysisParameters extends BaseAnalysisParameters {
    latent_variables: LatentParameterOption[];
}

export interface DifferentialExpressionContinuousAnalysisParameters extends BaseAnalysisParameters {
    covariates: ParameterOption[];
}

export interface GeneSetEnrichmentAnalysisParameters extends BaseAnalysisParameters {
    gene_set_collections: GeneSetCollection[];
}
export interface SeuratOverRepresentationAnalysisParameters extends BaseAnalysisParameters {
    gene_set_collections: GeneSetCollection[];
}

export interface PeakAnalysisParameters extends BaseAnalysisParameters {
    test: boolean;
}

export interface SurvivalAnalysisParameters extends BaseAnalysisParameters {
    other: boolean;
}

export interface ClusteringAnalysisParameters extends BaseAnalysisParameters {
    targets: ParameterOption[];
}
export interface CoverageAnalysisParameters extends BaseAnalysisParameters {
    targets: ParameterOption[];
}

export interface TranscriptionFactorEnrichmentAnalysisParameters extends BaseAnalysisParameters {
    tf_target_collection: TFTargetCollection;
}
export interface FunctionalAnnotationAnalysisParameters extends BaseAnalysisParameters {
    differential_is_enabled: boolean;
    sample_is_enabled: boolean;
    consensus_peak_is_enabled: boolean;
    samples: LatentParameterOption[];
    genomes: DisplayableShortname[];
}

export interface ProteinProteinInteractionAnalysisParameters extends BaseAnalysisParameters {
    biomarker_list: BiomarkerListAnalysisParameter[];
    differential_is_enabled: boolean;
    file_url: string;
    google_sheet_url: string;
    target_list_is_enabled: boolean;
}

export interface SampleCorrelationAnalysisParameters extends BaseAnalysisParameters {
    groups: AnalysisGroups;
}

export interface OverlapAnalysisParameters extends BaseAnalysisParameters {
    biomarker_list: BiomarkerListAnalysisParameter[];
    differential_is_enabled: boolean;
    file_url: string;
    google_sheet_url: string;
    target_list_is_enabled: boolean;
}

export interface SeuratMarkerExpressionAnalysisParameters extends BaseAnalysisParameters {
    cell_count: number;
    latent_variables: LatentParameterOption[];
}

export interface SeuratModuleScoreAnalysisParameters extends BaseAnalysisParameters {
    cell_count: number;
    latent_variables: LatentParameterOption[];
}

export type AnalysisParameters = BaseAnalysisParameters &
    (
        | AssaySummaryAnalysisParameters
        | DifferentialExpressionAnalysisParameters
        | FunctionalAnnotationAnalysisParameters
        | GeneSetEnrichmentAnalysisParameters
        | SeuratMarkerExpressionAnalysisParameters
        | SeuratModuleScoreAnalysisParameters
        | OverlapAnalysisParameters
        | PeakAnalysisParameters
        | ProteinProteinInteractionAnalysisParameters
        | SampleCorrelationAnalysisParameters
        | SeuratDifferentialExpressionAnalysisParameters
        | SeuratOverRepresentationAnalysisParameters
        | SurvivalAnalysisParameters
        | TranscriptionFactorEnrichmentAnalysisParameters
    );
